import React, { useState, useEffect } from "react";
import "../../assets/styles/_contentTable.scss";
import { getAllVideos } from "../../services/Dashboard/getAllvideos";
import ContentTableItem from "./ContentTableItem";
import { getAllPlaylists } from "../../services/Dashboard/getAllPlaylists";
import { ReactComponent as Group66 } from '../../icons/dashboard/Content/Group366.svg';
import { ReactComponent as AddIcon } from '../../icons/dashboard/Content/add.svg';
import { ReactComponent as PlayIcon } from '../../icons/dashboard/Content/playVideo.svg';
import { ReactComponent as PlayPassiveIcon } from '../../icons/dashboard/Content/playVideoPassive.svg';
import { ReactComponent as PlayPlaylistIcon } from '../../icons/dashboard/Content/playPlaylist.svg';
import { ReactComponent as PlayPlaylistPassiveIcon } from '../../icons/dashboard/Content/playPlaylistPassive.svg';
import { ReactComponent as PreviousIcon } from '../../icons/dashboard/Content/prev.svg';
import { ReactComponent as NextIcon } from '../../icons/dashboard/Content/next.svg';

function ContentTable() {
  const [contentSelect, setContentSelect] = useState("video");
  const [popUp, setPopUp] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    getAllVideos().then((res) => setVideos(res));
    getAllPlaylists().then((res) => setPlaylists(res));

    return () => {};
  }, []);

  const popUpOpen = () => {
    setPopUp(true);
  };
  const playlistIcon = contentSelect === "playlists" ? <PlayPlaylistIcon /> : <PlayPlaylistPassiveIcon />
  const videosIcon =  contentSelect === "video" ? <PlayIcon /> : <PlayPassiveIcon />

  return (
    <div className="rightmenu">
      <div className="contentTable">
        {popUp ? (
          <div>
            <div className="shadow" id="shadow"></div>

            <div className="popUp">
              <button
                className="popUpCloseButt"
                onClick={() => {
                  setPopUp(false);
                }}
              >
                <img src="/icons/Vectorclose.svg" alt="" />
              </button>
              <h3>Manage Thumbnails </h3>
              <h5>Existing thumbnails </h5>
              <div className="thumbnailsContent">
                <div className="thumbnailsContentRow">
                  <Group66 />
                  <Group66 />
                  <Group66 />
                </div>
                <div className="thumbnailsContentButtons">
                  <PreviousIcon />
                  <NextIcon />
                </div>
                <div className="thumbnailsContentRow">
                  <Group66 />
                  <Group66 />
                  <Group66 />
                </div>
              </div>
              <button className="addButton">
                <AddIcon />
              </button>
            </div>
          </div>
        ) : null}
        <div className="contentType">
          <h3>Type Of Content</h3>
          <div className="contentTypeButtons">
            <button
              onClick={() => {
                setContentSelect("video");
              }}
              className={
                contentSelect === "video" ? "contentTypeButtonActive" : null
              }
            >
              {videosIcon}
              Videos
            </button>
            <button
              onClick={() => {
                setContentSelect("playlists");
              }}
              className={
                contentSelect === "playlists" ? "contentTypeButtonActive" : null
              }
            >
              {playlistIcon}
              Playlists
            </button>
          </div>
        </div>

        <div>
          <div>
            <ul className="tableHeader">
              <li>Video</li>
              <li>Title </li>
              <li>Views</li>
              <li>Tags</li>
              <li>Date</li>
              <li>Options</li>
            </ul>
          </div>
          {contentSelect === "video"
            ? videos.map((obj, i) => {
                return (
                  <ContentTableItem
                    key={i}
                    item={obj[1][0]}
                    popUpOpenFunc={popUpOpen}
                  />
                );
              })
            : playlists.map((obj, i) => {
                return (
                  <ContentTableItem
                    key={i}
                    item={obj[1][0]}
                    popUpOpenFunc={popUpOpen}
                  />
                );
              })}
        </div>
      </div>
    </div>
    // </div>
  );
}

export default ContentTable;
