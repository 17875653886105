//This component get the following  parameters
//  title: <string>
//  views: <object>, parameter and types total:<number> , average:<number>
//  tags:<object>,parameter and types type:<string> , contentType:<string>  , lang:<string>  , gen:<string>  , rating:<string>
//  date:<string>  




import React, { useState } from "react";
import { ReactComponent as VideoImage } from '../../icons/dashboard/Content/videoImg.svg';
import { ReactComponent as OptionButton } from '../../icons/dashboard/Content/optionButton.svg';


function ContentTableItem(props) {
  const [optionButton, setOptionButton] = useState(false);
  console.log(props);
  return (
    <div>
      <div className="tableItem">
        <VideoImage />
        <h4>{props?.item?.title} </h4>
        <div className="tableItemViews">
          <div className="tableItemViewsChild">
            <span>Total Views </span>
            <h6>{props?.item?.views?.total} </h6>
          </div>
          <div className="tableItemViewsChild">
            <span>Average Watch Duration </span>
            <h6>{props?.item?.views?.average} </h6>
          </div>
        </div>
        <ul>
          <li>Type: {props?.item?.type}</li>
          <li>Content type: {props?.item?.tags?.contentType}</li>
          <li>Lang: {props?.item?.language}</li>
          <li>Gen: {props?.item?.genre}</li>
          <li>Rating: {props?.item?.rating}</li>
        </ul>
        <h5>{props?.item?.date}</h5>
        <div>
          <OptionButton className="optionButton" onClick={()=>{
              setOptionButton(!optionButton)
          }} />
          {optionButton ? 
            <div>

            <div className="shadowForChangeClick" onClick={()=>setOptionButton(false)}></div>
           <div className="optionButtonPopUP">
            <button onClick={()=>{
              setOptionButton(false)
              props.popUpOpenFunc()
            } }>Manage Thumbnail</button>
            <button>Take Down Video </button>
            <button>Geo Lock Video </button>
          </div>
          </div>
          :null}
         
        </div>
      </div>
    </div>
  );
}

export default ContentTableItem;
