export const getAllVideos=async()=>{
    // const response = await fetch(
    //     "http://localhost:8009/content/home/is_multipart=true",
    //     {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   let data = await response.json();
    // return  Object.entries(data.data)
    return Object.entries({"action":[{"id":2,"title":"Test Movie First","thumbnails":["0fb5dd26-8c23-42e7-bd66-446b86fd1017.jpg","9a47822e-f969-490c-9b6f-544def2085ed.jpg"],"description":"When we were about to test movie one. We found out that this might not be it and it changed our lives forever","language":"en","genre":["Action","Mythological","Sci-Fi"],"rating":"PG-13","duration":"1Hr 40Mins","rating_tags":["Violence"]},{"id":3,"title":"Test Movie Second","thumbnails":["0fb5dd26-8c23-42e7-bd66-446b86fd1017.jpg","9a47822e-f969-490c-9b6f-544def2085ed.jpg"],"description":"When we were about to test movie two. We found out that this might not be it and it changed our lives forever","language":"es","genre":["Action","Mythological","Space"],"rating":"R","duration":"1Hr 40Mins","rating_tags":["Violence","Sex"]}]})
}