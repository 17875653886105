//this component receives an object with the following parameters
//title:<string>
//viewsCount:<number>
//commentCount:<number>
//imgUrl:<string>
import React from 'react'
import { ReactComponent as EyeIcon } from '../../icons/dashboard/LatestContentCard/EyelatestCountView.svg';
import { ReactComponent as MessageIcon } from '../../icons/dashboard/LatestContentCard/MessagelatestContentComment.svg';

function LatestContentCard(props) {
    return (
        <div className=" latestComponent">
            <img src={props?.video?.imgUrl} alt="" />
            <h4>{props?.video?.title}</h4>
            <div className="latestComponentInfoBlock">
                <div className="latestComponentInfoBlockChild"><EyeIcon /><h6>{props?.video?.viewsCount}</h6></div>
                <div className="latestComponentInfoBlockChild"><MessageIcon /><h6>{props?.video?.commentCount}</h6></div>

            </div>
        </div>
    )
}

export default LatestContentCard
