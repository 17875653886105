//this component get an array in which there are three recent videos

import React, { useEffect, useState } from "react";
import "../../assets/styles/_overview.scss";
import { ReactComponent as IncreasingArrow } from "../../icons/dashboard/Overview/topArrow.svg";
import { ReactComponent as ServerIcon } from "../../icons/dashboard/Overview/serversIcon.svg";
import { ReactComponent as CloudIcon } from "../../icons/dashboard/Overview/cloudIcon.svg";
import { ReactComponent as DiskIcon } from "../../icons/dashboard/Overview/diskIcon.svg";

import LatestContentCard from "./LatestContentCard";
import OverviewGraph from "./OverviewGraph";
import { getLatestContent } from "../../services/Overview/latestContent";
import { getUserInformationBySignIn } from "../../services/Overview/userInformation";
import { getUserInformationBySignUp } from "../../services/Overview/userInformation";

import {
  getVideoAnalyticsByViews,
  getVideoAnalyticsByTime,
} from "../../services/Overview/videoAnalytics";
import {
  getWebSiteAnalyticsByVisits,
  getWebSiteAnalyticsByCountry,
} from "../../services/Overview/webSiteAnalytics";

function Overview() {
  const [videoAnalyticsByViews, setVideoAnalyticsByViews] = useState([]);
  const [videoAnalyticsByTime, setVideoAnalyticsByTime] = useState([]);
  const [webAnalyticsByVisits, setWebAnalyticsByVisits] = useState([]);
  const [webAnalyticsByCountry, setWebAnalyticsByCountry] = useState([]);
  const [userInfoBySignIn, setUserInfoBySignIn] = useState([]);
  const [userInfoBySignUp, setUserInfoBySignUp] = useState([]);
  const [latestContent, setLatestContent] = useState([]);

  useEffect(() => {
    setVideoAnalyticsByViews(getVideoAnalyticsByViews());
    setVideoAnalyticsByTime(getVideoAnalyticsByTime());
    setWebAnalyticsByVisits(getWebSiteAnalyticsByVisits());
    setWebAnalyticsByCountry(getWebSiteAnalyticsByCountry());
    console.log(webAnalyticsByCountry);
    setUserInfoBySignIn(getUserInformationBySignIn());
    setUserInfoBySignUp(getUserInformationBySignUp());
    setLatestContent(getLatestContent());
    return () => {};
  }, []);

  const numberOverall = (number) => {
    if (number / 1000 >= 1) {
      let tmp = "";
      let newNumber = String(number);
      let newNumberStart = newNumber.slice(
        0,
        String(Math.ceil(number / 1000)).length
      );
      console.log(newNumberStart, Math.ceil(number / 1000));
      let newNumberEnd = newNumber.slice(
        String(Math.ceil(number / 1000)).length
      );
      return tmp.concat(newNumberStart, "," + newNumberEnd);
    }
  };
  return (
    <div className="rightmenu">
      <div className="container">
        <div className="row">
          <div className="col-12 overviewHeader">
            <span>Latest Content</span>
          </div>
        </div>
        <div className="d-flex flex-wrap latestContent">
          {latestContent.map((elem, id) => {
            return (
              <div className="col-lg-4">
                <LatestContentCard key={id} video={elem} />
              </div>
            );
          })}
        </div>
        <div className="hostingInfo">
          <div className="card-heading">
            <span>Hosting Information </span>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-lg-3 hostingInfoBlock ">
              <h4>Servers</h4>
              <div className="d-flex">
                <ServerIcon />
                <div>
                  <h5>Server Location: India</h5>
                  <h5>Server Type: Shared upgrade to private click here</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 hostingInfoBlock">
              <h4>Storage</h4>
              <div className="d-flex">
                <DiskIcon />
                <div>
                  <h5>Storage: 20 out of 250 GB used</h5>
                  <h5>Increase storage click here</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 hostingInfoBlock">
              <h4>CDN</h4>
              <div className="d-flex">
                <CloudIcon />
                <div>
                  <h5>CDN status: Active </h5>
                  <h5>
                    Bandwidth usage: 25 GB of 1 TB used. Increase bandwidth here
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="videoAnalytics">
          <div className="card-heading">
            <span>Videos Analytics </span>
          </div>
          <div className="d-flex flex-wrap justify-content-around">
            <div className="col-lg-5 videoAnalyticsBlock">
              <h5>Total views today</h5>

              <h6>{numberOverall(videoAnalyticsByViews?.total_views)}</h6>

              <h6>
                {videoAnalyticsByViews?.percent_change} %{" "}
                <span>
                  <IncreasingArrow
                    style={
                      videoAnalyticsByViews?.percent_change > 0
                        ? {
                            height: "14px",
                            marginLeft: "10px",
                            width:"10px",
                          }
                        : {
                            height: "14px",
                            marginLeft: "10px",
                            width:"10px",
                            transform: "rotate(180deg)",
                          }
                    }
                  />
                </span>
              </h6>

              <h4>Number of hours </h4>
              <div className="graf">
                <OverviewGraph size="big" data={videoAnalyticsByViews} />
              </div>
              <h3>hours of the day </h3>
            </div>

            <div className="col-lg-5 videoAnalyticsBlock">
              <h5>Average watching hours </h5>

              <h6>{videoAnalyticsByTime?.overall_average} /view</h6>
              <h6>
                {videoAnalyticsByTime?.percent_change} %{" "}
                <span>
                  <IncreasingArrow
                    style={
                      videoAnalyticsByTime?.percent_change > 0
                        ? {
                            height: "14px",
                            marginLeft: "10px",
                            width:"10px",
                          }
                        : {
                            height: "14px",
                            marginLeft: "10px",
                            width:"10px",
                            transform: "rotate(180deg)",
                          }
                    }
                  />
                </span>
              </h6>
              <h4>Number of hours </h4>
              <div className="graf">
                <OverviewGraph size="big" data={videoAnalyticsByTime} />
              </div>
              <h3>hours of the day </h3>
            </div>
          </div>
        </div>
        <div className="videoAnalytics">
          <div className="card-heading">
            <span>Website analysis </span>
          </div>
          <div className="d-flex flex-wrap justify-content-around">
            <div className="col-lg-5 videoAnalyticsBlock">
              <h5>Total unique visits</h5>
              <h6>{numberOverall(webAnalyticsByVisits?.total_visits)}</h6>
              <h6>
                {webAnalyticsByVisits?.percent_change} %{" "}
                <span>
                  <IncreasingArrow
                    style={
                      webAnalyticsByVisits?.percent_change > 0
                        ? {
                            height: "14px",
                            marginLeft: "10px",
                            width:"10px",
                          }
                        : {
                            height: "14px",
                            marginLeft: "10px",
                            width:"10px",
                            transform: "rotate(180deg)",
                          }
                    }
                  />
                </span>
              </h6>
              <h4>Number of visits </h4>
              <div className="graf">
                <OverviewGraph size="big" data={webAnalyticsByVisits} />
              </div>
              <h3>hours of the day </h3>
            </div>
            <div className="col-lg-5 visitsByCountryBlock ">
              <div
                style={{ padding: "10px" }}
                className="row d-flex flex-column justify-content-around"
              >
                <div className="col-12">
                  <h5 style={{ marginBottom: "0px" }}>Total unique visits </h5>
                </div>

                <div className="col-12 d-flex justify-content-around align-items-center  smallOverviewBlock">
                  <h6>{webAnalyticsByCountry?.[0]?.[0]} </h6>
                  <h6>{webAnalyticsByCountry?.[0]?.[1]} </h6>

                  <h6>
                    {webAnalyticsByCountry?.[0]?.[2]} %{" "}
                    <span>
                      <IncreasingArrow
                        style={
                          webAnalyticsByCountry?.[0]?.[2] > 0
                            ? {
                                height: "14px",
                                marginLeft: "10px",
                                width:"10px",
  
                              }
                            : {
                                height: "14px",
                                marginLeft: "10px",
                                width:"10px",
  
                                transform: "rotate(180deg)",
                              }
                        }
                      />
                    </span>{" "}
                  </h6>
                  <div>
                    <OverviewGraph size="small" data={webAnalyticsByVisits} />
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-around smallOverviewBlock">
                  <h6>{webAnalyticsByCountry?.[1]?.[0]} </h6>
                  <h6>{webAnalyticsByCountry?.[1]?.[1]} </h6>

                  <h6>
                    {webAnalyticsByCountry?.[1]?.[2]} %{" "}
                    <span>
                      <IncreasingArrow
                        style={
                          webAnalyticsByCountry?.[1]?.[2] > 0
                            ? {
                                height: "14px",
                                marginLeft: "10px",
                                width:"10px",
  
                              }
                            : {
                                height: "14px",
                                marginLeft: "10px",
                                width:"10px",
  
                                transform: "rotate(180deg)",
                              }
                        }
                      />
                    </span>
                  </h6>
                  <div>
                    <OverviewGraph size="small" data={webAnalyticsByVisits} />
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-around smallOverviewBlock">
                  <h6>{webAnalyticsByCountry?.[2]?.[0]} </h6>
                  <h6>{webAnalyticsByCountry?.[2]?.[1]} </h6>

                  <h6>
                    {webAnalyticsByCountry?.[2]?.[2]} %{" "}
                    <span>
                      <IncreasingArrow
                        style={
                          webAnalyticsByCountry?.[2]?.[2] > 0
                            ? {
                                height: "14px",
                                marginLeft: "10px",
                                width:"10px",
  
                              }
                            : {
                                height: "14px",
                                marginLeft: "10px",
                                width:"10px",
  
                                transform: "rotate(180deg)",
                              }
                        }
                      />
                    </span>
                  </h6>
                  <div>
                    <OverviewGraph size="small" data={webAnalyticsByVisits} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="videoAnalytics lastGrafBlock">
          <div className="card-heading">
            <span>User Information </span>
          </div>
          <div className="d-flex flex-wrap justify-content-around">
            <div className="col-lg-5 videoAnalyticsBlock">
              <h5>Paid users this month </h5>
              <h6>{numberOverall(userInfoBySignIn?.total)}</h6>
              <h6>
                {userInfoBySignIn?.percent_change} %{" "}
                <span>
                  <IncreasingArrow
                    style={
                      userInfoBySignIn?.percent_change > 0
                        ? {
                            height: "14px",
                            marginLeft: "10px",
                            width:"10px",
                          }
                        : {
                            height: "14px",
                            marginLeft: "10px",
                            width:"10px",
                            transform: "rotate(180deg)",
                          }
                    }
                  />
                </span>
              </h6>
              <h4>Number of users </h4>
              <div className="graf">
                <OverviewGraph size="big" data={userInfoBySignIn} />
              </div>
              <h3>Days </h3>
            </div>
            <div className="col-lg-5 videoAnalyticsBlock">
              <h5>Registered users this month </h5>
              <h6>{userInfoBySignUp?.total}</h6>
              <h6>
                {userInfoBySignUp?.percent_change} %{" "}
                <span>
                  <IncreasingArrow
                    style={
                      userInfoBySignUp?.percent_change > 0
                        ? {
                            height: "14px",
                            marginLeft: "10px",
                            width:"10px",
                          }
                        : {
                            height: "14px",
                            marginLeft: "10px",
                            width:"10px",
                            transform: "rotate(180deg)",
                          }
                    }
                  />
                </span>
              </h6>
              <h4>Number of users </h4>
              <div className="graf">
                <OverviewGraph size="big" data={userInfoBySignUp} />
              </div>
              <h3>Days </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
