
export const getUserInformationBySignIn = () => {
    return {
      num_users: [10, 20, 30, 40, 50],
      days: [1, 2, 3, 4, 5],
      total: 3450,
      percent_change: 5.0,
    };
};
  
  
  
export const getUserInformationBySignUp = () => {
    return {
      num_users: [0.4, 0.5, 1.0, 1.2, 0.25],
      days: [1, 2, 3, 4, 5],
      total: 0.67,
      percent_change: -6.0,
    };
  };