export const getVideoAnalyticsByViews = () => {
  return {
    num_views: [10, 20, 30, 40, 50],
    hours: [1, 2, 3, 4, 5],
    total_views: 25359,
    percent_change: 5.0,
  };
};

export const getVideoAnalyticsByTime = () => {
  return {
    average_times: [0.4, 0.5, 1.0, 1.2, 0.25],
    hours: [1, 2, 3, 4, 5],
    overall_average: 0.67,
    percent_change: -6.0,
  };
};
