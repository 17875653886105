import React, { useState } from "react";
import "../../assets/styles/_leftMenu.scss"
function LeftMenu(props) {
  const [selectedButton, setSelectedButton] = useState("Overview");
  return (
    <div>
      <div className="leftMenu">
        <div
          className={
            selectedButton === "Overview"
              ? "menuItem menuItemActive"
              : "menuItem"
          }
        >
          <button
            name="Overview"
            onClick={() => {
              setSelectedButton("Overview");
              props.changeComponent("Overview");
            }}
          >
            Overview
          </button>
        </div>
        <div
          className={
            selectedButton === "Deployments"
              ? "menuItem menuItemActive"
              : "menuItem"
          }
        >
          <button
            name="Deployments"
            onClick={() => {
              setSelectedButton("Deployments");
              props.changeComponent("Deployments");
            }}
          >
            Deployments
          </button>
        </div>
        <div
          className={
            selectedButton === "Content"
              ? "menuItem menuItemActive"
              : "menuItem"
          }
        >
          <button
            name="Content"
            onClick={() => {
              setSelectedButton("Content");
              props.changeComponent("Content");
            }}
          >
            Content
          </button>
        </div>
        <div
          className={
            selectedButton === "Analytics "
              ? "menuItem menuItemActive"
              : "menuItem"
          }
        >
          <button
            name="Analytics "
            onClick={() => {
              setSelectedButton("Analytics ");
              props.changeComponent("Analytics ");
            }}
          >
            Analytics{" "}
          </button>
        </div>
        <div
          className={
            selectedButton === "Monetization"
              ? "menuItem menuItemActive"
              : "menuItem"
          }
        >
          <button
            name="Monetization"
            onClick={() => {
              setSelectedButton("Monetization");
              props.changeComponent("Monetization");
            }}
          >
            Monetization
          </button>
        </div>
        <div
          className={
            selectedButton === "User Features "
              ? "menuItem menuItemActive"
              : "menuItem"
          }
        >
          <button
            name="User Features "
            onClick={() => {
              setSelectedButton("User Features ");
              props.changeComponent("User Features ");
            }}
          >
            User Features{" "}
          </button>
        </div>
        <div
          className={
            selectedButton === "Settings"
              ? "menuItem menuItemActive"
              : "menuItem"
          }
        >
          <button
            name="Settings"
            onClick={() => {
              setSelectedButton("Settings");
              props.changeComponent("Settings");
            }}
          >
            Settings
          </button>
        </div>
        <div className="leftMenuBottomBlock">
          <div class="menuItem">
            <button name="Settings">Billing</button>
          </div>
          <div class="menuItem">
            <button name="Settings">Logout</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftMenu;
