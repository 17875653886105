import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import "./LandingPage.scss";
import { ReactComponent as StatsClock } from '../../icons/stats_clock.svg';
import { ReactComponent as StatsDollar } from '../../icons/stats_dollar.svg';
import { ReactComponent as AdvantagesLogo } from '../../icons/advantage.svg';
import { ReactComponent as AboutUS } from '../../icons/about.svg';
import { ReactComponent as Qualities } from '../../icons/qualities.svg';
import { ReactComponent as IntroLogo } from '../../icons/intro.svg';
import { ReactComponent as DomainLogo } from '../../icons/featureIcons/domain.svg';
import { ReactComponent as NoCodingLogo } from '../../icons/featureIcons/no_coding.svg';
import { ReactComponent as MdashLogo } from '../../icons/featureIcons/mdash.svg';
import { ReactComponent as IntegrationsLogo } from '../../icons/featureIcons/integrations.svg';
import { ReactComponent as BrandLogo } from '../../icons/Logo.svg';
import facebook from '../../icons/facebook.png';
import insta from '../../icons/insta.png';
import twitter from '../../icons/twitter.png';


function useOutsideAlerter(ref, setMenu) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
		var a = false;
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
				setMenu(false)
				// var a = true;
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setMenu]);
}

const bookmark = (id, setMenu) => {
	document.querySelector(id).scrollIntoView();
	setMenu(false);
}
  
  

const LandingPage = () => {


	const [menu, setMenu] = useState(false);
	
	const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setMenu);
	
	return (
		<div className="outer">
			<div ref={wrapperRef} id="top-menu" className="container-block">
				<div className="header">
					<BrandLogo />
					<div className={menu? "mobile-menu active": "mobile-menu"}>
						<div className="icon" onClick={() => setMenu(!menu)}>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					<ul className="navbar-list">
					    <li className="list-item" onClick={() => bookmark("#advantages", setMenu)}>Features</li>
						<li className="list-item" onClick={() => bookmark("#advantages-continued", setMenu)}>Advantages</li>
						<li className="list-item" onClick={() => bookmark("#about", setMenu)}href="#about">About</li>
						<li className="list-item" onClick={() => bookmark("#schedule-demo", setMenu)}>Demo</li>
						<li className="list-item" onClick={() => bookmark("#schedule-demo", setMenu)}>View Demo</li>
					</ul>
				</div>
			</div>

			<div id="description" className="container-block pad-btm">
				<div className="section-wrap front-wrap">
					<div className="flex-50">
						<div className="front-heading">
							Build your streaming platform with FlowGiga.
						</div>
						<div className="front-desc">
							We have made creating your very own streaming platform DIY,
							a fully managed solution, that allows you to create your own version of
							Netflix/Prime.
					</div>
						<a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScZWBdOQKJY9VksxymdmIOQAkBuefWc-dd__n3NbxwAQzdY3w/viewform?embedded=true">
							<button className="get-started-button">Set-Up Demo</button>
						</a>
					</div>
					<IntroLogo className="flex-50 logopadding" />
				</div>
			</div>

			<div className="container-block section">
				<div className="section-wrap stats-box">
					<div className="stats-column">
						<StatsClock />
						<div className="stats">84+</div>
						<div className="stats-text">
							Mins/day global content<br />Streaming Average
                    </div>
					</div>
					<div className="stats-column">
						<StatsDollar />
						<div className="stats">150B</div>
						<div className="stats-text">
							Size of the streaming<br />industry by 2026.
                    </div>
					</div>
				</div>
			</div>

			<div id="advantages" className="container-block section">
				<div className="section-wrap advantages-wrap">
					<div className="flex-50 order2">
						<Qualities />
					</div>
					<div className="flex-50">
						<div className="advantage-heading">We ensure you get the best ablities</div>
						<div className="advantage-desc text-green">Amazing features & tools that allow you to deliver the best streaming experience to your audience
              <ul className="advantage-list">
								<li><i className='bx bxs-check-circle' />Fully managed platform! we take care of the tech infra.</li>
								<li><i className='bx bxs-check-circle' />Omni-channel set-up take it live over web, mobile, smart TVs.</li>
								<li><i className='bx bxs-check-circle' />Pre-built monetization models (AVOD,TVOD, and SVOD)</li>
								<li><i className='bx bxs-check-circle' />Detailed reports & Analytics suite </li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div id="advantages-continued" className="container-block section">
				<div className="section-wrap advantages-continued-wrap">
					<div className="left-sec">
						<div className="big-font centre-element top-margin">
							Massive advantages with FlowGiga.
          </div>
						<div className="small-font">
							We have made digital content distribution easy, with fully managed technology enabling unique advantages.
          </div>
						<div className="advantage-items">
							<div className="item">
								<div className="item-logo">
									<DomainLogo />
								</div>
								<div className="item-details">
									<div className="item-heading">Your domain</div>
									<div className="item-desc">Our solutions go live on your own domain </div>
								</div>
							</div>
							<div className="item">
								<div className="item">
									<div className="item-logo">
										<NoCodingLogo />
									</div>
									<div className="item-details">
										<div className="item-heading">Zero Coding</div>
										<div className="item-desc">Easy DIY set-up with no coding required. </div>
									</div>
								</div>
							</div>
							<div className="item">
								<div className="item-logo">
									<MdashLogo />
								</div>
								<div className="item-details">
									<div className="item-heading">Mdash</div>
									<div className="item-desc">Easily manage content, analytics &&nbsp;more</div>
								</div>
							</div>
							<div className="item">
								<div className="item-logo">
									<IntegrationsLogo />
								</div>
								<div className="item-details">
									<div className="item-heading">Integrations</div>
									<div className="item-desc">Prebuilt, with multi-payment gateways & more </div>
								</div>
							</div>
						</div>
					</div>
					<div className="right-sec">
						<AdvantagesLogo />
					</div>
				</div>
			</div>

			<div id="about" className="container-block section">
				<div className="section-wrap advantages-wrap height400">
					<div className="flex-50 order2 imgheight">
						<AboutUS />
					</div>
					<div className="flex-50">
						<div className="advantage-heading text-center">About us</div>
						<div className="advantage-desc text-green about-desc">At Flowgiga we are a start-up that focuses on empowering content creators of all sizes with our solutions allowing them to open & maximize opportunities to create, distribute and monetize content in the ruthless digital world of today.
							<p>
								We focus on various aspects of future technologies with Streaming, Augmented Reality, and Artificial Intelligence, at the core of our focus.
									</p>
							<p>
								Our team consists of experts in technology and content with a combined experience of over 3 decades in the industry.
									</p>
						</div>
					</div>
				</div>
			</div>

			<div id="schedule-demo" className="container-block schedule-wrap">
				<div className="schedule-demo-wrap">
					<div className="heading">
						Schedule A Demo Now!!
          </div>
					<div className="desc">
						Unlock The Potential of private content streaming.
          </div>
				</div>
				<div className="btn-wrap">
					<a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScZWBdOQKJY9VksxymdmIOQAkBuefWc-dd__n3NbxwAQzdY3w/viewform?embedded=true">
						<button className="subscribe-button">Set-up Demo</button>
					</a>
				</div>
			</div>

			<div className="footer">
				<div className="container-block">
					<div className="footer-left">
						<BrandLogo />
						<div className="footer-text">
							FlowGiga is a streaming infrastruture platform, with unique approach and features
						</div>
						<ul className="social-links">
							<li>
							<a href="https://www.facebook.com/Flowgiga/" target="_blank"><img src={facebook} alt="facebook"/></a>
							</li>
							<li>
							<a href="https://twitter.com/flowgiga" target="_blank"><img src={twitter} alt="twitter" /></a>
							</li>
							<li>
							<a href="https://www.instagram.com/flowgiga/" target="_blank"><img src={insta} alt="insta" /></a>
							</li>
							{/* <li>
							<a href=" https://www.linkedin.com/company/flowgiga" target="_blank"><img src={linkedin} alt="insta" /></a>
							</li> */}
						</ul>
						<div className="copyright">
							©2020FlowGiga
						</div>
					</div>
					<div className="footer-right">
						<div className="footer-links-wrap">
							<div className="link-col-heading">
								Product
						</div>
							<ul className="col-links">
								<li>Demo</li>
								<li>Pricing</li>
								<li>Server</li>
							</ul>
						</div>
						<div className="footer-links-wrap">
							<div className="link-col-heading">
								Engage
						</div>
							<ul className="col-links">
								<li><a href="#about" style={{"textDecoration": "none", "color": "#575a89"}}>About Us</a></li>
								<li><Link style={{"textDecoration": "none", "color": "#575a89"}} to="/privacy-policy">Privacy Policy</Link></li>
							</ul>
						</div>
						<div className="footer-links-wrap">
							<div className="link-col-heading">
								Earn Money
						</div>
							<ul className="col-links">
								<li>Affiliate</li>
								<li>Become Partner</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export default LandingPage;
