export const getAllPlaylists=async()=>{
    // const response = await fetch(
    //     "http://localhost:8009/content/home/is_multipart=false",
    //     {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   let data = await response.json();
  //  return  Object.entries(data.data)
  return Object.entries({"action":[{"id":1,"title":"Test Series","thumbnails":["0fb5dd26-8c23-42e7-bd66-446b86fd1017.jpg","9a47822e-f969-490c-9b6f-544def2085ed.jpg"],"description":"Conclusion of the conclusion","language":"hi","genre":["Action","Thriller","Sci-Fi"]}],"thriller":[{"id":1,"title":"Test Series","thumbnails":["0fb5dd26-8c23-42e7-bd66-446b86fd1017.jpg","9a47822e-f969-490c-9b6f-544def2085ed.jpg"],"description":"Conclusion of the conclusion","language":"hi","genre":["Action","Thriller","Sci-Fi"]}]})
}