import React, { useState } from 'react'
// import CmsHeader from './CmsHeader'
import LeftMenu from './LeftMenu'
import ContentTable from './ContentTable'
import Overview from './Overview';
// import CmsFooter from './CmsFooter';

function SecondPage() {
    const [OpeningComponentName, setOpeningComponentName] = useState("Overview");
    const changeComponent=(name)=>{
setOpeningComponentName(name)
    }
    return (
        <div>
            {/* <CmsHeader/> */}
            <div  style={{display:"flex"}}>

            <LeftMenu changeComponent={changeComponent}/>
            {OpeningComponentName==="Content" ?  <ContentTable/>:null}
            {OpeningComponentName==="Overview" ?  <Overview/>:null}

           
            </div>
            {/* <CmsFooter/> */}
        </div>
    )
}

export default SecondPage
