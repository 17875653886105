import React from 'react'
import { Switch, Route, BrowserRouter } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import DashboardPage from "./pages/Dashboard/Dashboard";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";


function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/dashboard" exact><DashboardPage/></Route>
                <Route path="/" exact><LandingPage/></Route>
                <Route path="/privacy-policy" exact><PrivacyPolicy/></Route>
            </Switch>
        </BrowserRouter>
      );   
}

export default App;