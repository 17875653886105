//this component receives an object with the following 2 arrays
//xAxis:<array>
//yAxis:<array>

import React from "react";
import { LineChart, Line } from 'recharts';
function OverviewGraph(props) {
  
  const xAxis=props?.data?.hours ? props.data.hours:props?.data?.days ? props.data.days :0

  const yAxis=props?.data?.num_views ? props.data.num_views:props?.data?.average_times ? props.data.average_times :props?.data?.num_visits ? props.data.num_visits : props?.data?.num_users ? props.data.num_users:0
    const data = [{name: 'first', uv: yAxis?.[0], pv: xAxis?.[0], amt: 2400},{name: 'second', uv: yAxis?.[1], pv: xAxis?.[1], amt: 2400},{name: 'third', uv: yAxis?.[2], pv: xAxis?.[2], amt: 2400},{name: 'fourth', uv: yAxis?.[3], pv:xAxis?.[3], amt: 2400},{name: 'fifth', uv: yAxis?.[4], pv: xAxis?.[4], amt: 2400},{name: 'six', uv: yAxis?.[5], pv: xAxis?.[5], amt: 2400},{name: 'seven', uv: yAxis?.[6], pv: xAxis?.[6], amt: 2400}];
  return (
    <LineChart width={props.size==="big" ? 180:75} height={props.size==="big" ? 143:30} data={data}>
    <Line type='monotone' dataKey='uv' stroke={props.size==="big" ? "#05396B":"#379583"} strokeWidth={props.size==="big" ? 3:2} dot={false} />
   
  </LineChart>
  );
}

export default OverviewGraph;
