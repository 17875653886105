export const getLatestContent=()=>{

 return   [
        {
          title: "testTitle",
          viewsCount: 6000,
          commentCount: 300,
          imgUrl:
            "https://media.licdn.com/dms/image/C4E1BAQFL13oNzxFwaw/company-background_10000/0?e=2159024400&v=beta&t=PItljeMkUK84loZSmYJzCO2ksoXxCBzkzH53TKh1wWk",
        },
        {
          title: "testTitle",
          viewsCount: 7000,
          commentCount: 300,
          imgUrl:
            "https://media.licdn.com/dms/image/C4E1BAQFL13oNzxFwaw/company-background_10000/0?e=2159024400&v=beta&t=PItljeMkUK84loZSmYJzCO2ksoXxCBzkzH53TKh1wWk",
        },
        {
          title: "testTitle",
          viewsCount: 8000,
          commentCount: 300,
          imgUrl:
            "https://media.licdn.com/dms/image/C4E1BAQFL13oNzxFwaw/company-background_10000/0?e=2159024400&v=beta&t=PItljeMkUK84loZSmYJzCO2ksoXxCBzkzH53TKh1wWk",
        },
      ]
}