

export const getWebSiteAnalyticsByVisits = () => {
  return {
    num_visits: [100, 200, 300, 400, 500],
    hours: [1, 2, 3, 4, 5],
    total_visits: 15600,
    percent_change: 5.0,
  };
};

export const getWebSiteAnalyticsByCountry = () => {
  let res=[]
  let data= [{name:"India",value:'1000',percent_change:"5.0"},{name:"Armenia",value:'500',percent_change:"-4.5"},{name:"UAE",value:'600',percent_change:"7.5"}]
  data.map(item=>res.push(Object.values(item)))
  console.log(res);
  return res
};